.whatsapp {
    bottom: 10px;
    right: -35px;
    position: fixed;
}

@media screen and (max-width: 480px) {
    .whatsapp {
        bottom: 250px;
        right: -21rem;
        position: fixed;
        width: 100%;
        overflow: auto;
    }
}