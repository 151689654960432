.about{
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    margin-top:5%;
    margin-bottom: -70px;
    color: white;
}


.a-disc{
    width: 80%;
    margin-left: 90px;
    text-align: center;
    justify-content: center;
    color: white;
}

.a-disc h1{
    margin-top:3rem;
    font-size: 2rem;
    color: orange;
}

.a-disc p{
    font-size: 1.2rem;
    text-align: justify;
}
.a-disc p{
    text-align: center;
    align-items: center;
    
}

.a-image{
    height: 300px;
    width: 300px;
    border: 1px solid transparent;
    border-radius: 50%;
}

@media screen and (max-width: 480px){
    .about{
        display: inline;
        overflow: auto;
    }
    
    .a-disc{
        margin-left: 50px;
        margin-top: -20px;
        z-index:1;
        width: 80%;
    }

}