.c-heading{
    width: 100%;
    height: 60px;
    background-color:rgb(209, 138, 5);
    font-size: 40px;
    font-weight: bold;
    text-align:center;
    align-items: center;
    justify-content:center;
}
.contact{
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
}

.reach-us{
    width: 45%;
    margin-right: 80px;
    margin-left: 40px;
}
.reach-us h1{
    text-align: center;
    font-weight: bold;
}
.c-form{
    width: 55%;
}
.c-form input {
    width:500px;
    height: 3rem;
    margin: 5px 5px;
    padding: 2px 15px;
    outline: none;
    border: 2px solid var(--orange);
    border-radius: 8px;
    font-size: 16px;   
    background: black;
    color: white;
    border-bottom: 1px solid white ;
}
.c-form textarea{
    width:500px;
    height: 7rem;
    margin: 5px 5px;
    padding: 2px 15px;
    outline: none;
    border: 2px solid var(--orange);
    border-radius: 8px;
    font-size: 16px; 
    background: black;
    color: white;
    border: 1px solid white;
}
.f-btn{
    height:50px;
    width: 200px;
    margin-left:150px ;
    margin-top: 20px;
    background: black;
    color: white;
    box-shadow: 2px 2px 2px white;
}
.c-map{
    margin-top: 250px;
    filter:grayscale(100%);
}

.n-icons{
   margin-left: 480px;
   padding: 3px 3px;
}

a{
   margin-right: 50px;
}

@media screen and (max-width: 480px){
    div{
        overflow: auto;
    }
    .contact{
        display: inline;
    }
    .c-map{
        margin-bottom:4rem;
    }
    .n-icons{
        color: white;
    }
    a{
        margin-right: -220px;
    }
    .c-form input{
        width: 2rem;
    }
    .c-map{
        margin-top: 40px;
    }
    .c-right .user{
        width: 23rem;
        margin-left: -48px;
    }
    .reach-us{
        width: 90%;
    }
}