#sample-video{
    display: flex;
    justify-content: space-around;
}
.all-video{
    display: flex;
    justify-content: space-around;
}
.itemOne{
    background-color: white;
    margin: 0 30px 0 30px;
    width: 60%;
}
.itemTwo{
    background-color: white;
    margin: 0 30px 0 30px;
    width: 45%;
}
.project-title{
    background-color: orangered;
    color: white;
    text-align: center;
    align-items: center;
    height: 50px;
    margin-top:0px;
    margin-bottom:0px;
}
.v-heading{
    color: white;
    margin-top: 150px;
}
.v-heading h3{
    margin-left: -70px;
}
.span-feedback{
    display: none;
}
.img{
    width: 632px;
     height:315px;
}
iframe{
    width: 535px;
    height: 310px;
}
@media screen and (max-width: 480px){
    #sample-video{
       display: inline-block;
       width:100%;
    }
    .span-feedback{
        margin-top: 30px;
        display: block;
    }
    .v-heading{
        display: none;
    }
    .all-video{
        display: inline-block;
        margin-right: 10px;
        margin-left:-10px;
    }
    .itemOne{
        width: 100%;
    }
    .img{
        margin-left: -10px;
        padding: 2px 5px;
    }
    .img iframe{
        padding: 2px 5px;
        width:100%;
    }
}