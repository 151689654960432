.footer {
    display: flex;
    align-items: center;
    /* margin:-4.5rem -3.5rem; */
    /* position: relative; */
    /* margin-top: 7rem; */
} 

.f-content {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 12rem;
    gap: 2rem;
    color: black;
}

.f-icons {
    display: flex;
    gap: 5rem;
}

@media screen and (max-width: 480px) {
    .f-content {
        margin-top: 2rem;
        gap: 2.5rem;
        transform: scale(.5);
    }
    .footer{
        margin-top: -1rem;
        overflow: auto;
    }
}