.c-heading {
    width: 100%;
    height: 60px;
    background-color: rgb(209, 138, 5);
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.contact {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
}

.reach-us {
    width: 55%;
    margin-right: 80px;
    margin-left: 20px;
}

.reach-us h1 {
    text-align: center;
    font-weight: bold;
}

/* .c-form {
    width: 55%;
} */

/* .user input {
    width: 500px;
    height: 3rem;
    margin: 5px 5px;
    padding: 2px 15px;
    outline: none;
    border: 2px solid var(--orange);
    border-radius: 8px;
    font-size: 16px;
    background: black;
    color: white;
    border-bottom: 1px solid white;
} */

/* .c-form textarea {
    width: 500px;
    height: 7rem;
    margin: 5px 5px;
    padding: 2px 15px;
    outline: none;
    border: 2px solid var(--orange);
    border-radius: 8px;
    font-size: 16px;
    background: black;
    color: white;
    border: 1px solid white;
} */

/* .f-btn input {
    padding: 11px 26px 11px 26px;
    font-size: 16px;
    padding: 10px;
    height: 10px;
    width: 200px;
    margin-left: 150px;
    margin-top: 20px;
    background: black;
    color: white;
    box-shadow: 2px 2px 2px white;
} */

/* ------------------------------- */
.c-right{
    display: flex;
    justify-content: center;
    position: relative;
    flex: 1;
}

.c-right>form
{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
}

.c-right .user{
    width: 28rem;
    height:20px;
    padding: 20px 10px;
    margin-top: 20px;
    outline: none;
    background: black;
    color: white;
    border-bottom: 1px solid white;
    /* border-radius: 8px; */
    font-size: 16px;   
}

.button{
    z-index: 1;
    cursor: pointer;
    width: 150px;
    background-color: rgb(30, 179, 30);
}
.contact-span{
    margin-left: '26px';
}
/* ------------------------------------ */

@media screen and (max-width: 480px) {
    .contact {
        display: inline;
        overflow:auto;
    }
    
    .reach-us {
        margin-right: 10px;
        margin-top: 60px;
        width: 100%;
    }
    .c-right>form{
        margin-left: 35px;
    }
    .button{
       margin-left: -40px;
    }
.contact-span{
   margin-left: 0px;
  }
  
  .reach-us p{

  }
}