/* .benifits{
    display: flex;
    justify-content:space-between;
    text-align: center;
    color: white;
    width: 100%;
    margin-top: 30px;
} */
.b-heading{
    margin-top: 50px;
    color: white;
    text-align: center;
}
.b-content{
    width: 85%;
    margin: auto;
    background-color: rgb(236, 235, 235);
    border: 1px solid transparent;
    border-radius: 10px;
}
.b-heading{
    color: black;
    text-align:center;

}
.b-heading h3{
    color: green;
    font-size:20px;
}
.b-heading p{
    margin:0px 60px 0px 60px;
    font-size: larger;
}

@media screen and (max-width: 480px){
    .b-heading p{
        margin:0px 2px 0px 2px;
    }
    .contact-us-place{
        margin-left: 20px;
    }
}

/* .b-image img{
    width:400px;
    height:400px;
    margin-left: 50px;
    margin-right: 50px;
}

.b-content{
    width: 70%;
    margin: auto;
    margin-left: 50px;
    margin-right: 40px;
}
.b-content h1{
    text-align: center;
}
.b-content ul li{
    text-decoration: none;
    text-align: left;
}
.b-content p {
    text-align: left;
}
.b-content h4{
    text-align: left;
}
hr{
    display: none;
}
.b-icon{
    display: none;
}

@media screen and (max-width: 480px){
    .b-image img{
        display: none;
        overflow: auto;
    }
    hr{
        display:block;
    }
    .benifits{
        overflow: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .b-icon{
        margin-left: 30px;
        display: block;
        width: 30%;
    }
    .b-content{
        width: 70%;
    }

}

 */
