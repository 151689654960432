.video {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 80px;
    color: white;
}

.v-heading {
    margin-left: 20px;
}

.v-heading h1 {
    color:white;
}

.y-link {
    margin-right: 60px;
    margin-top: 50px;
    border: 2px solid transparent;
    box-shadow: 2px 2px 2px orange;

}

@media screen and (max-width: 480px) {
    .video {
      margin-top: 100px;
        display: inline;
        overflow: auto;
    }

    .y-link iframe {
        width: 100%;
        margin-left: 30px;
        border: none;
    }

}