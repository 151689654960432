body{
  width: 100%;
}

@media screen and (max-width: 480px){
  body{
    width: 100%;
    overflow: auto;
  }
}
