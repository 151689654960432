nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color:black;
    color:white;
}

nav .title {
    color:white;
    font-size: 1.5rem;
    margin: 1rem;
    font-weight: bold;
}

nav ul {
    display: flex;
}

nav ul li {
    list-style: none;
    color:white;
    font-weight: bold;
}

nav ul a {
    display: block;
    text-decoration: none;
    padding: 0.3rem;
    margin: 0 1.9rem;
    border-radius: 0.5rem;
}

nav .menu {
    display: none;
    position: absolute;
    top: 0.95rem;
    right: 0.5rem;
}


@media (max-width: 480px) {
    nav .title{
        font-size:1.3rem;
    }
    nav .menu {
        display: flex;
    }

    nav {
        flex-direction: column;
        align-items: flex-start;
        width: 550px;
        overflow: auto;
    }

    nav ul {
        display: none;
        flex-direction: column;
        margin-top: -10px;
        margin-left: 50px;
        width: 100%;
        margin-bottom: 0.25rem;
    }

    nav ul.open {
        display: flex;
    }

    nav ul li {
        width: 90%;
        text-align: center;
        font-size: 1.2rem;
        color:white;
        font-weight: bold;
    }

    nav ul a {
        margin: 0rem 0.1rem;
    }

    .n-button {
        display: none;
    }
}