* {
    box-sizing: border-box;
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
}

.banner-img {
    height: 700px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    filter: blur(1ypx);
    opacity: 0.32;
}

.banner-text {
    /* background-color: #f7f7f7; */
    opacity: 0.95;
    color: white;
    border: 1px solid transparent;
    border-radius: 15px;
    position: absolute;
    top: 25%;
    left:0%;
    width: 100%;
    padding: 40px 0;
    text-align: center;
    font-weight: bold;
}

.banner-text h4 {
    color: black;
}

.banner-text h1 {
    font-size: 3rem;
    color: black;
}
.demo-div h4{
    color: white;
}
.banner-text a {
    display: inline-block;
    padding: 10px 50px;
    border: 2px solid #fff;
    font-size: 18px;
    text-decoration: none;
    color: black;
    margin-top: 15px;
}

.banner-text h1 {
    color: red;
}

.banner-text h2 {
    margin-top: -30px;
    margin-left: 20px;
    letter-spacing: 18px;
}

.banner-text h3 {
    padding: 0 150px 0px 150px;
}

.button {
    height: 40px;
    width: 100px;
    margin-left: 40px;
    background: black;
    color: white;
}

.demo-div {
    padding: 0px 150px 0px 140px;
}

.demo-box {
    margin-top: -15px;
    margin-bottom: 8px;
}

#d,
#e,
#m,
#o {
    height: 30px;
    width: 40px;
    font-size: 50px;
    background-color: red;
    color: white;
    margin-left: 5px;
    border: 1px solid red;
    border-radius: 6px;
}
.facebook-icon{
    margin-left: 65px;
}
.facebook-icon a{
    color: white;
    padding:0px;
    border: none;
}
.facebook-icon a img{
   padding-top: 10px;
}

@media screen and (max-width: 480px) {
    .banner-img {
        width: 100%;
        height: 650px;
        margin-bottom: 40px;
    }
    .banner-img img {
        width: 100%;
        background-size: cover;
        overflow:hidden;
    }
    .banner-text {
        width:580px;
        height:100%;
        top: 20%;
        left:-90px;
        overflow:hidden;
    }

    .banner-text .button {
        margin-right: 140px;
    }

    .banner-text p{
        font-size: 0.5rem;
        color: black;
        padding:0;
        width: 100%;
    }
    #d,
    #e,
    #m,
    #o {
        height: 20px;
        width: 40px;
        font-size: 35px;
    }
    .facebook-icon img{
        margin-right: 270px;
    }
}